import React from 'react'
import Modal from 'react-modal'

interface Props {
  OpenButtonComponent: JSX.Element,
  ModalInnerComponent: JSX.Element,
  modalClassName: string,
  modalAppElement: Element
}
interface State {
  isOpenModal: boolean
}

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '100',
    overflow: 'scroll'
  }
}

export default class ModalWithButton extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {isOpenModal: false}
  }

  openModal = (event): void => {
    this.setState({isOpenModal: true})
    document.body.style.overflow = 'hidden';
    event.preventDefault()
  }

  closeModal = (event): void => {
    this.setState({isOpenModal: false})
    document.body.style.overflow = 'auto';
    event.preventDefault()
  }

  renderOpenButtonComponent = (): JSX.Element => {
    return React.cloneElement(this.props.OpenButtonComponent, {openModal: this.openModal})
  }

  renderModalInnerComponent = (): JSX.Element => {
    return React.cloneElement(this.props.ModalInnerComponent, {closeModal: this.closeModal})
  }

  render() {
    return (
      <>
        {this.renderOpenButtonComponent()}
        <Modal
          isOpen={this.state.isOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          className={this.props.modalClassName}
          appElement={this.props.modalAppElement}
        >
          {this.renderModalInnerComponent()}
        </Modal>
      </>
    )
  }
}
