import React from 'react'
import Modal from 'react-modal'
import ReactDOM from 'react-dom'
import ModalWithButton from 'components/ModalWithButton'
import Icon from 'components/Icon'

const overviewClassName = '.overview-btn'

const OverviewButton = (props) => (
  <a href='#' className='service-overview-button' onClick={props.openModal}>
    <img src='/images/overview_button.png' />
  </a>
)

const OverviewModalInner = (props) => (
  <div className='overview-wrapper'>
    <a className="close" onClick={props.closeModal}>
      <Icon name="close" size="24px" className="fill-text-secondary" />
    </a>
    <img src='/images/service_overview.png' className='overview-img' />
  </div>
)

class Overview extends React.Component<{}, {isOpenModal: boolean}> {
  constructor(props) {
    super(props)
    this.state = {
      isOpenModal: false
    }
  }

  render() {
    return (
      <ModalWithButton OpenButtonComponent={<OverviewButton />} ModalInnerComponent={<OverviewModalInner />} modalClassName="overview-modal" modalAppElement={document.querySelector(overviewClassName)} />
    )
  }
}

const $overviewBtn: HTMLElement = document.querySelector(overviewClassName)

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render (
    <Overview />,
    $overviewBtn
  )
})
